import React from 'react';
import { Auth } from 'aws-amplify';
import { useRouter } from 'next/router';
import { AsyncHandler } from '@requity-homes/component-library';
import { deleteCookie, setCookie } from 'cookies-next';
import { cookiesPartnerRedirectPath } from '@requity-homes/utils';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function withAuthenticator(Component: any): (props: any) => JSX.Element {
  return function WrappedWithAuthenticator(props: any) {
    const router = useRouter();
    const [isAuthenticated, setIsAuthenticated] = React.useState<
      boolean | undefined
    >(undefined);

    React.useEffect(() => {
      Auth.currentAuthenticatedUser()
        .then(() => {
          setIsAuthenticated(true);
          deleteCookie(cookiesPartnerRedirectPath);
        })
        .catch(() => {
          // Parse the URL to get the path
          const url = new URL(window.location.href);
          const path = url.pathname;
          // expires in 30 days
          setCookie(cookiesPartnerRedirectPath, path, {
            expires: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000),
          });
          // User is not signed in, redirect to sign-in page
          router.push('/auth/sign-in');
        });
    }, []);

    return (
      <AsyncHandler loading={isAuthenticated === undefined}>
        <Component {...props} />
      </AsyncHandler>
    );
  };
}
