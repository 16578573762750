import { useRouter } from 'next/router';
import { twMerge } from 'tailwind-merge';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { Link, Logo } from '@requity-homes/component-library';

export function Navigation({ openNavigation, setOpenNavigation }) {
  const router = useRouter();

  return (
    <aside
      className={`md:w-3/12 lg:w-3/12 md:bg-coral-light py-4 px-6 font-sans text-md text-grey-2 ${
        openNavigation ? 'flex-1' : ''
      }`}
    >
      <div className="flex flex-col h-full">
        <div
          className={`md:mb-20 flex justify-between md:pb-0 ${
            openNavigation ? 'pb-24' : ''
          }`}
        >
          <Link href="/">
            <Logo color="coral" className="h-6" />
          </Link>
          <span
            className="md:hidden border border-coral-light rounded-lg cursor-pointer font-black px-4 py-2 text-sm -mt-1"
            onClick={() => setOpenNavigation((prev) => !prev)}
          >
            Menu
          </span>
        </div>

        <div
          className={twMerge(
            `flex flex-col justify-between flex-1 md:flex ${
              openNavigation ? 'flex' : 'hidden'
            }`,
          )}
        >
          <div className="flex-1 flex flex-col gap-4">
            <div>
              <h2 className="mb-2 font-bold text-coral-medium">Leads</h2>
              <Link
                href="/"
                className="mb-2 md:block relative no-underline text-grey-2 hover:text-grey-2 -mr-6"
                onClick={(event) =>
                  router?.route === '/' ? event.preventDefault() : null
                }
              >
                <div
                  className="sm:ml-6 md:ml-0 md:justify-center lg:justify-start"
                  id="overview"
                >
                  <h2
                    className={`${
                      openNavigation ? '' : 'hidden'
                    } md:hidden lg:block`}
                  >
                    Overview
                  </h2>
                </div>
                <span
                  className="sm:hidden md:block lg:hidden text-center absolute w-full"
                  style={{ fontSize: 10 }}
                >
                  <ReactTooltip
                    anchorId="overview"
                    place="bottom"
                    content="Overview"
                  />
                </span>
                {router?.route === '/' && (
                  <div className="hidden md:block absolute -right-3 top-0.5 h-5 w-5 bg-white rotate-45"></div>
                )}
              </Link>
              <Link
                href="/client-status"
                className="mb-2 md:block relative no-underline text-grey-2 hover:text-grey-2 -mr-6"
                onClick={(event) =>
                  router?.route === '/client-status'
                    ? event.preventDefault()
                    : null
                }
              >
                <div
                  className="sm:ml-6 md:ml-0 md:justify-center lg:justify-start"
                  id="client-status"
                >
                  <h2
                    className={`${
                      openNavigation ? '' : 'hidden'
                    } md:hidden lg:block`}
                  >
                    Client Status
                  </h2>
                </div>
                <span
                  className="sm:hidden md:block lg:hidden text-center absolute w-full"
                  style={{ fontSize: 10 }}
                >
                  <ReactTooltip
                    anchorId="client-status"
                    place="bottom"
                    content="Client Status"
                  />
                </span>
                {router?.route === '/client-status' && (
                  <div className="hidden md:block absolute -right-3 top-0.5 h-5 w-5 bg-white rotate-45"></div>
                )}
              </Link>
              <Link
                href="/invite-clients"
                className="mb-2 md:block relative no-underline text-grey-2 hover:text-grey-2 -mr-6"
                onClick={(event) =>
                  router?.route === '/invite-clients'
                    ? event.preventDefault()
                    : null
                }
              >
                <div
                  className="sm:ml-6 md:ml-0 md:justify-center lg:justify-start"
                  id="invite-clients"
                >
                  <h2
                    className={`${
                      openNavigation ? '' : 'hidden'
                    } md:hidden lg:block`}
                  >
                    Invite Clients
                  </h2>
                </div>
                <span
                  className="sm:hidden md:block lg:hidden text-center absolute w-full"
                  style={{ fontSize: 10 }}
                >
                  <ReactTooltip
                    anchorId="invite-clients"
                    place="bottom"
                    content="Invite Clients"
                  />
                </span>
                {router?.route === '/invite-clients' && (
                  <div className="hidden md:block absolute -right-3 top-0.5 h-5 w-5 bg-white rotate-45"></div>
                )}
              </Link>
            </div>
            <Link
              href="/notifications"
              className="mb-2 md:block relative no-underline text-coral-medium hover:text-coral-medium -mr-6"
            >
              <div className="md:ml-0 flex gap-2 md:justify-center lg:justify-start font-bold">
                <h2>Notifications</h2>
              </div>
              {router?.route === '/notifications' && (
                <div className="hidden md:block absolute -right-3 top-0.5 h-5 w-5 bg-white rotate-45"></div>
              )}
            </Link>
            <div>
              <h2 className="mb-2 font-bold text-coral-medium">Resources</h2>
              <Link
                href="https://www.requityhomes.com/partner-resource/main"
                target="_blank"
                className="mb-2 md:block relative no-underline text-grey-2 hover:text-grey-2 -mr-6"
              >
                <div
                  className="sm:ml-6 md:ml-0 md:justify-center lg:justify-start"
                  id="get-started"
                >
                  <h2
                    className={`${
                      openNavigation ? '' : 'hidden'
                    } md:hidden lg:block`}
                  >
                    Get Started
                  </h2>
                </div>
                <span
                  className="sm:hidden md:block lg:hidden text-center absolute w-full"
                  style={{ fontSize: 10 }}
                >
                  <ReactTooltip
                    anchorId="get-started"
                    place="bottom"
                    content="Get Started"
                  />
                </span>
              </Link>
              <Link
                href="https://www.requityhomes.com/partner-resource/marketing"
                target="_blank"
                className="mb-2 md:block relative no-underline text-grey-2 hover:text-grey-2 -mr-6"
              >
                <div
                  className="sm:ml-6 md:ml-0 md:justify-center lg:justify-start"
                  id="marketing"
                >
                  <h2
                    className={`${
                      openNavigation ? '' : 'hidden'
                    } md:hidden lg:block`}
                  >
                    Marketing
                  </h2>
                </div>
                <span
                  className="sm:hidden md:block lg:hidden text-center absolute w-full"
                  style={{ fontSize: 10 }}
                >
                  <ReactTooltip
                    anchorId="marketing"
                    place="bottom"
                    content="Marketing"
                  />
                </span>
              </Link>
            </div>
            <Link
              href="/account"
              className="mb-5 md:block relative no-underline text-coral-medium hover:text-coral-medium -mr-6"
            >
              <div className="md:ml-0 flex gap-2 md:justify-center lg:justify-start font-bold">
                <h2>Account</h2>
              </div>
              {router?.route === '/account' && (
                <div className="hidden md:block absolute -right-3 top-0.5 h-5 w-5 bg-white rotate-45"></div>
              )}
            </Link>
          </div>
        </div>
      </div>
    </aside>
  );
}
