import { ReactNode, useState } from 'react';
import { Heading } from '@requity-homes/component-library';
import { Navigation } from '../navigation/navigation';
export interface MainContentProps {
  children: ReactNode;
  className?: string;
  title?: string;
}

export function MainContent({
  children,
  className,
  title = '',
}: MainContentProps) {
  const [openNavigation, setOpenNavigation] = useState(false);

  return (
    <main
      className={`flex flex-col flex-1 gap-6 ${
        openNavigation ? 'pb-0' : 'pb-6'
      } sm:p-8 sm:pt-0 sm:px-2 md:p-0 md:pr-6 md:flex-row ${className || ''}`}
    >
      <Navigation
        setOpenNavigation={setOpenNavigation}
        openNavigation={openNavigation}
      />
      <div
        className={`md:w-9/12 lg:w-9/12 flex flex-col md:flex md:max-h-screen px-2 md:px-0 ${
          openNavigation ? 'hidden' : ''
        }`}
      >
        <Heading
          level="h5"
          element="h2"
          className="mb-8 mt-2 mr-12 text-right text-lg hidden md:block"
        >
          {title}
        </Heading>
        {children}
      </div>
    </main>
  );
}
