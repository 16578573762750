import { Dispatch } from 'react';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import {
  Heading,
  Text,
  AsyncHandler,
  Popup,
  Button,
  FormCheckbox,
  FormTextarea,
  trackEvent,
} from '@requity-homes/component-library';
import {
  useSendClientReminderEmailMutation,
  ClientApplicationInfo,
  Invitation,
  ApplicationStatus,
} from '../../graphql/generated';
import { emailTemplates } from '../../const';

interface RemindClientProps {
  client: Invitation | ClientApplicationInfo;
  user: CurrentUserInfo;
  onClose: Dispatch<null>;
}

export function RemindClientModal({
  onClose,
  client,
  user,
}: RemindClientProps) {
  const [sendClientReminderEmail, { loading, error }] =
    useSendClientReminderEmailMutation();

  const validationSchema = yup.object({
    emailContent: yup.string().required('Please enter message.'),
  });

  const initialValues = {
    clientEmail: true,
    partnerEmail: true,
    emailContent: emailTemplates[client.applicationStatus]?.emailContent(
      client,
      user,
    ),
  };

  let styles = {
    checkBox: 'checked:bg-green-light checked:hover:bg-green-light',
    submitButton: 'bg-green border-green',
    cancelButton: 'border-green text-green',
  };

  if (client.applicationStatus === ApplicationStatus.Invited) {
    styles = {
      checkBox: 'checked:bg-purple-light checked:hover:bg-purple-light',
      submitButton: 'bg-purple border-purple',
      cancelButton: 'border-purple text-purple',
    };
  }

  return (
    <Popup size="large" title="">
      <Heading level="h5" element="h6" fontFace="sans" className="mb-2">
        Email Reminder
      </Heading>
      <Text useCase="small">
        Reach out to your client and help them create an account and start their
        application.
      </Text>
      <Text className="font-bold mt-4" useCase="legal">
        To
      </Text>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (formData) => {
          await sendClientReminderEmail({
            variables: {
              payload: {
                clientEmail: client.email,
                emailBody: formData.emailContent,
                emailSubject:
                  emailTemplates[client.applicationStatus].emailSubject,
                previewText:
                  emailTemplates[client.applicationStatus].previewText,
                partnerEmail: formData.partnerEmail
                  ? user?.attributes?.email
                  : undefined,
              },
            },
          });
          trackEvent('Remind Client', {
            clientEmail: client.email,
          });
          onClose();
        }}
      >
        {(formikProps) => {
          return (
            <Form>
              <div className="flex flex-col md:flex-row gap-2 justify-between mb-4">
                <div className="flex gap-1">
                  <FormCheckbox
                    label=""
                    name="clientEmail"
                    disabled
                    className={styles.checkBox}
                    inline
                  />
                  <div>
                    <Text useCase="small">
                      {client.givenName} {client.familyName}
                    </Text>
                    <Text useCase="legal">{client.email}</Text>
                  </div>
                </div>

                <div className="flex gap-1">
                  <FormCheckbox
                    label=""
                    name="partnerEmail"
                    inline
                    className={styles.checkBox}
                  />
                  <div>
                    <Text useCase="small">
                      {user?.attributes?.given_name}{' '}
                      {user?.attributes?.family_name}
                    </Text>
                    <Text useCase="legal">{user?.attributes?.email}</Text>
                  </div>
                </div>
              </div>
              <Text className="font-bold pb-6" useCase="legal">
                Subject: {emailTemplates[client.applicationStatus].emailSubject}
              </Text>
              <FormTextarea
                name="emailContent"
                className="h-80 text-xs py-4 rounded-xl"
              />

              <div
                className={`flex ${
                  loading ? 'justify-center mt-2' : 'justify-between'
                } items-center`}
              >
                <AsyncHandler
                  loading={loading}
                  error={error}
                  errorMessage="There was a problem fetching partner referrals. Please refresh the page or try again later."
                >
                  <div className="flex flex-col md:flex-row md:items-center md:justify-between md:w-full">
                    <Text useCase="small">
                      This email will be sent by Requity Homes in your behalf.
                    </Text>
                    <div className="flex gap-2 justify-end">
                      <Button
                        onClick={() => onClose()}
                        type="button"
                        hierarchy="secondary"
                        color="navy"
                        className={`my-4 py-0 w-32 sm:px-0 self-center mx-0 h-12 md:h-auto ${styles.cancelButton}`}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        disabled={!formikProps.values.emailContent}
                        hierarchy="primary"
                        className={`my-4 py-0 w-32 sm:px-0 self-center text-white mx-0 h-12 md:h-auto ${styles.submitButton}`}
                      >
                        Send Email
                      </Button>
                    </div>
                  </div>
                </AsyncHandler>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Popup>
  );
}
