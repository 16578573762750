import {
  ApplicationStatus,
  Invitation,
  ClientApplicationInfo,
} from '../graphql/generated';
import { getPartnerReferralLink } from '@requity-homes/utils';
export interface EmailTemplate {
  emailContent: (
    client: Invitation | ClientApplicationInfo,
    user: CurrentUserInfo,
  ) => string;
  emailSubject: string;
  previewText: string;
}

export const emailTemplates: Record<ApplicationStatus, EmailTemplate> = {
  [ApplicationStatus.ApplicationStarted]: {
    emailContent: (client, user) => {
      const message =
        `Hi ${client.givenName} ${client.familyName},\n\n` +
        "I just wanted to give you a friendly nudge because I noticed you started your rent-to-own application but haven't quite finished it yet. No worries, we're here to help you complete it and get you one step closer to your dream home! \n\n" +
        `As a friendly reminder, please provide your details about your household income, financial situation, and desired location. Completing your pre-qualification will give you a clear picture of your budget and can make your home shopping experience more enjoyable. \n\n` +
        "If you need a hand or have any questions about the pre-qualification process, we're always here to help! Just give us a call at 1-855-952-6492 or send an email to support@requityhomes.com, and Requity Homes' friendly team will be more than happy to assist you. Don't wait any longer! Complete your pre-qualification now and get ready to find your perfect home.\n\n" +
        'Best Regards, \n' +
        `${user?.attributes?.given_name} ${user?.attributes?.family_name}\n`;

      return message;
    },
    emailSubject:
      "👋 Hey there! Let's finish your rent-to-own home application!",
    previewText:
      "Don't let your dream home slip away! Complete your rent-to-own application now and make homeownership a reality",
  },
  [ApplicationStatus.PreApprovalAccepted]: {
    emailContent: (client, user) => {
      const message =
        `Hi ${client.givenName} ${client.familyName},\n\n` +
        "Congratulations on your pre-qualification!  So excited that you're one step closer to your dream home. To get fully approved, Requity Homes just needs a few more documents from you. \n\n" +
        `By getting fully approved, you'll:
          1. Secure your place in our rent-to-own program, bringing you closer to homeownership.
          2. Shop with confidence knowing exactly your budget and preferences.  \n\n` +
        "If you're unsure about which documents to submit or need assistance, don't hesitate to reach out! You can contact Requity Homes at 1-855-952-6492 or support@requityhomes.com, and their friendly team will be more than happy to help. Remember, getting fully approved is the key to unlocking the door to your dream home. So, submit your financial documents today and let's make your dream a reality!\n\n" +
        'Best Regards, \n' +
        `${user?.attributes?.given_name} ${user?.attributes?.family_name}\n`;

      return message;
    },
    emailSubject:
      "Let's Get You Fully Approved! Submit Your Financial Documents Now 📄🏠",
    previewText:
      "Congratulations on being pre-qualified for Requity Homes' rent-to-own program! We are excited to help you on your journey to home ownership. We just need a few more supporting documents from you to get started.",
  },
  [ApplicationStatus.FullApprovalStarted]: {
    emailContent: (client, user) => {
      const message =
        `Hi ${client.givenName} ${client.familyName},\n\n` +
        "Congratulations on your pre-qualification!  So excited that you're one step closer to your dream home. To get fully approved, Requity Homes just needs a few more documents from you. \n\n" +
        `By getting fully approved, you'll:
          1. Secure your place in our rent-to-own program, bringing you closer to homeownership.
          2. Shop with confidence knowing exactly your budget and preferences.  \n\n` +
        "If you're unsure about which documents to submit or need assistance, don't hesitate to reach out! You can contact Requity Homes at 1-855-952-6492 or support@requityhomes.com, and their friendly team will be more than happy to help. Remember, getting fully approved is the key to unlocking the door to your dream home. So, submit your financial documents today and let's make your dream a reality!\n\n" +
        'Best Regards, \n' +
        `${user?.attributes?.given_name} ${user?.attributes?.family_name}\n`;

      return message;
    },
    emailSubject:
      "Let's Get You Fully Approved! Submit Your Financial Documents Now 📄🏠",
    previewText:
      "Congratulations on being pre-qualified for Requity Homes' rent-to-own program! We are excited to help you on your journey to home ownership. We just need a few more supporting documents from you to get started.",
  },
  [ApplicationStatus.Invited]: {
    emailContent: (client, user) => {
      const partnerInfo = {
        id: user.attributes.sub,
        givenName: user.attributes.given_name,
        familyName: user.attributes.family_name,
        partnerType: user.attributes['custom:occupation'],
      };

      const message =
        `Hi ${client.givenName} ${client.familyName},\n\n` +
        "I  hope you're having a fantastic day! Just wanted to give you a friendly nudge because I noticed that you haven't started your rent-to-own application yet. Requity Homes is a great stepping stone to help you transition from renting to owning a home. \n\n" +
        `Ready to start? Head on over to our <a href=${getPartnerReferralLink(
          partnerInfo,
          process.env.NEXT_PUBLIC_STAGE,
        )}>application portal</a>.\n\n` +
        "Need a helping hand? We've got your back! Give us a shout at 1-855-952-6492 or shoot an email to support@requityhomes.com, and Requity Homes' super friendly team will be more than happy to guide you through the process.\n\n" +
        "If you are interested in learning more about the program, you can also find additional information on their <a href='https://www.requityhomes.com/faq'>FAQs</a>.\n\n" +
        'Complete your rent-to-own application to bring your dreams closer to home!\n\n' +
        'Best Regards, \n' +
        `${user?.attributes?.given_name} ${user?.attributes?.family_name}\n`;

      return message;
    },
    emailSubject:
      'Take Your First Step Towards Home Ownership with Requity Homes 🏠',
    previewText:
      'Make your dream home a reality! Start your rent-to-own application to get one step closer to home ownership.',
  },
};
