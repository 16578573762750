import { ReactNode } from 'react';
import { Disclosure } from '@headlessui/react';
import { Icon } from '@requity-homes/component-library';

export interface InfoBoxProps {
  title: string;
  children: ReactNode;
  defaultOpen?: boolean;
}

export function InfoBox({ title, children, defaultOpen }: InfoBoxProps) {
  return (
    <Disclosure
      as="div"
      className="flex flex-col p-4 pl-0 gap-4 pt-2"
      defaultOpen={defaultOpen}
    >
      {({ open }) => (
        <>
          <Disclosure.Button className="flex font-bold text-sm text-grey-2 text-left">
            <>
              <Icon
                glyph="chevronDown"
                className={`h-5 w-5 transition-transform duration-500 motion-reduce:transition-none mr-4 ${
                  open ? 'rotate-180' : ''
                }`}
              />
              <span className="text-grey-1">{title}</span>
            </>
          </Disclosure.Button>
          <Disclosure.Panel className="text-xs text-grey-2 ml-9">
            {children}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
